import axios, { AxiosInstance } from 'axios';
import { apiSettings, FeelisService } from './apiSettings';

type Headers = {
	'Content-Type': string;
};

/**
 * Feelis Axios factory method creates axios client for the given Feelis.
 * @returns Axios instance
 */

export function feelisAxiosFactory(service: FeelisService): AxiosInstance {
	const effectiveSettings = apiSettings;
	const headers: Headers = {
		'Content-Type': 'application/json'
	};

	const feelisAxios = axios.create({
		baseURL: `${effectiveSettings.apiBaseUrl}/${service}`,
		headers
	});

	return feelisAxios;
}
