import { InfoTVNotification } from '../../models/InfoTV';
import { SingleInfoTVNotification } from './SingleInfoTVNotification';
import { InfoTvNotificationsContainer } from './infoTVStyledComponents';

interface Props {
	notifications: InfoTVNotification[];
}

export function InfoTVNotifications({ notifications }: Props) {
	return (
		<InfoTvNotificationsContainer>
			{notifications.map((noti, index) => (
				<SingleInfoTVNotification notification={noti} key={index} />
			))}
		</InfoTvNotificationsContainer>
	);
}
