import { useCallback, useEffect, useMemo, useState } from 'react';
export type ApiFactory<TApi> = () => TApi;

export function useSimpleApiQuery<TApi, TResponse>(
	apiFactory: ApiFactory<TApi>,
	queryFn: (api: TApi) => Promise<TResponse>,
	refreshTriggers: any[] = []
) {
	const [response, setResponse] = useState<TResponse>();
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState(false);
	const api = useMemo(() => apiFactory(), [apiFactory]);

	const doQuery = useCallback(async () => {
		try {
			setLoading(true);
			setResponse(undefined);
			const response = await queryFn(api);
			setResponse(response);
		} catch (error) {
			console.error(error);
			setError(error);
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, ...refreshTriggers]);

	useEffect(() => {
		void doQuery();
	}, [doQuery]);

	return { response, error, loading: loading };
}
