import { InfoTVNotification } from '../models/InfoTV';
import { basicGet } from './basicRestOperations';
import { feelisAxiosFactory } from './feelisAxiosFactory';

export function infoTVNotificationApiFactory() {
	const axios = feelisAxiosFactory('external');
	return {
		getLocationInfoTVNotifications: (locationId: string, floors: string) =>
			basicGet<InfoTVNotification[]>(axios, '/infotvnotifications', { locationId: locationId, floors: floors })
	};
}
