import { InfoTVNotification } from '../../models/InfoTV';
import { Column, DescText, InfoTvNotificationContainer, TitleText } from './infoTVStyledComponents';
import CampaignIcon from '@mui/icons-material/Campaign';

interface Props {
	notification: InfoTVNotification;
}

export function SingleInfoTVNotification({ notification }: Props) {
	return (
		<InfoTvNotificationContainer>
			<CampaignIcon style={{ fontSize: '4.5rem', color: '#D35400' }} />
			<Column>
				<TitleText>{notification.title}</TitleText>
				<DescText>{notification.description}</DescText>
			</Column>
		</InfoTvNotificationContainer>
	);
}
