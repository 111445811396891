import { useEffect, useState } from 'react';
import { useSimpleApiQuery } from './useSimpleApiQuery';
import { infoTVNotificationApiFactory } from '../api/infoTVNotificationApi';

const THIRTY_MINUTES = 1800000;

export function useGetInfoTVNotifications(locationId: string, floors?: string) {
	const [refreshKey, setRefreshKey] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setRefreshKey(key => key + 1);
		}, THIRTY_MINUTES);

		return () => clearInterval(intervalId);
	}, []);

	const {
		response: infoTVNotifications,
		loading,
		error
	} = useSimpleApiQuery(
		infoTVNotificationApiFactory,
		api => api.getLocationInfoTVNotifications(locationId, floors ?? ''),
		[locationId, floors, refreshKey]
	);

	return {
		infoTVNotifications,
		loading,
		error
	};
}
