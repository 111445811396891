/**
 * FeelisServices type includes the supported service names that are used in axios queries.
 * For example, 'GET http://<host>/<feelisservice>/<resource>'.
 */
export type FeelisService = 'external';

/**
 * ApiSettings configuration object
 */
export type ApiSettings = {
	/**
	 * Base api url for most end-point requests.
	 */
	apiBaseUrl: string;
};

const protocol = (): string => {
	return process.env.REACT_APP_USE_SSL === 'true' ? 'https' : 'http';
};

/**
 * Creates the api settings configuration object based on env variable values.
 *
 * @returns ApiSettings configuration object.
 */
export const apiSettingsFactory = (): ApiSettings => {
	const proto = protocol();
	const apiBaseUrl = `${proto}://${process.env.REACT_APP_HOST}/${process.env.REACT_APP_API_BASE}`;

	return { apiBaseUrl };
};

/**
 * Default api settings.
 */
export const apiSettings = apiSettingsFactory();
