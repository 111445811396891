import styled from 'styled-components';
import { flexColumn, flexRow } from '../../styles/styled-components/containers';

export const InfoTvNotificationsContainer = styled('div')`
	${flexRow}
	width: 100%;
	background-color: #fcebc2;
	padding: 1rem;
	gap: 2rem;
`;

export const InfoTvNotificationContainer = styled('div')`
	${flexRow}
	flex: 1;
	gap: 10px;
	justify-content: center;
`;

export const Column = styled('div')`
	${flexColumn};
`;

export const TitleText = styled('span')`
	font-weight: 600;
	color: #00195f;
	font-size: 2.2rem;
`;

export const DescText = styled('span')`
	font-weight: 500;
	color: #00195f;
	font-size: 1.6rem;
`;
